#home-page-background {
	background-size: cover;
	min-width: 100vw;
	min-height: 110vh;
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	background-position: center center;
	opacity: 0;;
	transition: opacity 0.5s;
}

#home-page-background.image-loaded {
	opacity: 1;
}

#home-page-text {
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	align-items: center;
	padding-left: 1rem;
    padding-right: 1rem;
	position: absolute;
	bottom: 20%;
	width: 100vw;
}

#home-page-text p {
	color: white;
	width: 100%;
	font-size: 18px;
	font-weight: bold;
}

#home-page-text #home-page-photo-credit {
	color: white;
	font-size: 12px;
	font-weight: bold;
	font-style: italic;
}


@media (min-width: 1024px) {
	#home-page-text {
		display: flex;
		justify-content: flex-end;
		flex-direction: column;
		align-items: flex-end;
		padding-left: 6rem;
		padding-right: 6rem;
	}
	
	#home-page-text p {
		color: white;
		width: 33%;
		font-size: 18px;
		font-weight: bold;
	}
}