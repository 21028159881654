.movie-shell {
    position: relative;
}
.movie-page-background {
	margin-top: 50px;
}
.movie-page-background * {
    background: transparent;
}

.movie-page-shell {
    margin: 0 1rem;
}

.iFrameClass.not-loaded {
    display: none;
}

.iFrameClass {

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
	height: 100%;
    margin: 0 auto;
}


.button-div {
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
	text-transform: uppercase;
	color: white;
	font-weight: bold;
}

.prevNext {
    cursor: pointer;
}

.movie-details-inner {
    margin: 20px;
    text-align: left;
    display: inline-block;
}

.movie-info {
	margin: 20px 0 200px;
}
.movie-info ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	color: white;
}
.movie-info ul li:first-child{
	font-weight: bold;
}
.movie-info ul li:nth-child(2){
	font-style: italic;
}
.movie-info p {
	color: white;
	margin: 10px 0;
	font-size: 24px;
}


@media (min-width: 1024px) {
	.movie-page-shell {
		margin: 0 6rem;
	}
	.movie-info {
		margin: 20px 0 200px;
	}
}
@media (min-width:600px) {

    /* .movie-page-shell {
        margin: 125px auto 0;
        width: 90%;
        max-width: 768px;
        padding: 0;
    }
    .movie-page-shell {
        margin: 125px auto 0;
        max-width: 768px;
    }
    .movie-shell {
        margin: 20px auto;
    }
    .iFrameClass {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        display: block;
        width: 100%;
    } */
}