.thumbnail {
	/* background-color: #212b5d; */
	border-radius: 20px;
	width: 100%;
	background-size: cover;
	background-position: center;
	cursor: pointer;
	min-height: 400px;
	opacity: 0;
	transition: opacity 2s;
	position: relative;
	overflow: hidden;
}
.thumbnail-loading {
	/* background-color: #212b5d; */
	border-radius: 20px;
	width: 100%;
	background-size: cover;
	background-position: center;
	cursor: pointer;
	min-height: 400px;
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}
.thumbnail-loading div {
	color: white;
	font-weight: bold;
}
.thumbnail.loaded {
	opacity: 1;
}
.thumbnail-cover {
	border-radius: 20px;
	background-color: #212b5d;
	color: white;
	position: absolute;
	top: 0;
	left:0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	opacity: 0;
	transition: opacity 0.25s;
}

.thumbnail:hover .thumbnail-cover {
	opacity: 1;
}