footer {
	position: fixed;
	width: 100vw;
	bottom: 0;
	left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: rgb(0, 0, 0);
    background: linear-gradient(163deg, rgba(255,255,255, 1) 0, rgba(194,202, 201, 1) 100%);
	height:45px;
	padding-left: 1rem;
	z-index: 9;
}

footer img {
    width: 30px;
    height: 30px;
	margin-right: 1rem;
}

@media (min-width: 1024px) {
	footer {
		padding-left: 6rem;
	}
}