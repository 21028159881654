.nav-wrapper {
    display: flex;
	flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
	top: 0;
	left: 0;
	z-index: 10;
    overflow: hidden;
}
.home.nav-wrapper {
	position: fixed;
}
#nav-top-band {
	background-color: #ebebee;
	height:45px;
	width: 100vw;
	margin-bottom: 1rem;
}
#nav-content {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 0 1rem;
}

#nav-content a img {
	width: 128px;
	/* height: 145px; */

}

.nav-hamburger {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: 9px;
    right: .8rem;
    cursor: pointer;
    opacity: 0;
    /* hide this */
    z-index: 20;
    /* and place it over the hamburger */
    -webkit-touch-callout: none;
}

.nav-hamburger:hover~.nav-hamburger-spans span {
    opacity: 1;
}

.nav-hamburger-spans {
    display: block;
    /* width: 40px;
    height: 32px; */
    position: absolute;
    top: 10px;
    right: 1rem;
    z-index: 11;
}

.nav-hamburger-spans span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: black !important;
    border-radius: 3px;
    z-index: 1;
    opacity: 0.5;
    transition: opacity 0.25s;
}

.nav-hamburger:checked~.nav-links {
    margin-top: 0;
}

.nav-hamburger:checked~.nav-hamburger-spans span {
    transition: transform 0.75s ease;
}

.nav-hamburger:checked~.nav-hamburger-spans span:nth-of-type(1) {
    transform: translate(0, 8px);
}

.nav-hamburger:checked~.nav-hamburger-spans span:nth-of-type(3) {
    transform: translate(0, -8px);
}

.nav-links {
    align-items: left;
    margin-top: -100%;
    display: flex;
    flex-direction: column;
    transition: all 1s ease-in-out;
}

.nav-links span {

    display: inline-block;
    margin: 5px 15px;
}

.nav-links span a {
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: right;
    font-size: 1rem;
    color: #FFF;
	font-weight: bold;
}
.nav-links.about-page span a {
    color: #FFF;
}

.nav-links span a:hover {
    text-decoration: none;
}

.nav-brand {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    display: block;
    font-size: 1.5rem;
    white-space: nowrap;
    width: 100%;
    background-color: white;
    position: relative;
    z-index: 10;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    text-decoration: none;
    font-weight: bold;
    color: black;
    text-align: left;
}

.nav-brand a {
    color: black
}

.nav-brand a:hover {
    text-decoration: none;
    color: black
}

@media (min-width: 1024px) {
	#nav-content {
		padding-left: 6rem;
		padding-right: 6rem;
	}
    .nav-wrapper {
        flex-wrap: nowrap;
    }
    .nav-hamburger {
        display: none;
    }
    .nav-hamburger-spans {
        display: none;
    }
    .nav-links {
        flex-basis: auto;
        flex-grow: 1;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        margin-top: 0;
        transition: none;
        padding: 0;
    }
    .nav-links span {
        cursor: pointer;
        display: inline-block;
        width: auto;
        text-align: right;
        margin: 0 0 0 100px;
        font-weight: bold;
    }
	.nav-links span:nth-child(2) {
        margin: 0 0 0 70px;
    }
}